import React, {useEffect, useState} from 'react';

import {themeGet} from '@styled-system/theme-get';
import {Events} from 'src/analytics';
import {Anchor} from 'src/components/controls';
import {Box, Flex, Image, Loading, SEOWithQuery} from 'src/components/shared';
import {Typography} from 'src/components/shared/fireworkTypography';
import {COMMITMENT_TO_INCLUSION} from 'src/routes/externalUrls';
import {MEMBERSHIP_CRITERIA} from 'src/routes/paths';
import {breakpoints} from 'src/styleguide/defaultTheme';
import {fonts} from 'src/styleguide/fireworkTypographyStyles';
import {fontWeights} from 'src/styleguide/typographyStyles';
import {LeadForm} from 'src/templates/leadForm/components/LeadForm';
import {applyPage as copy, seo} from 'src/utils/copy';
import {trackGoogleAdsPageLoad} from 'src/utils/googleAds';
import {sanityClient} from 'src/utils/sanity';
import styled from 'styled-components/macro';
import useSWR from 'swr';

const {H2, HeadLine, P2, CTA} = Typography;

const PixelImg = styled(Image).attrs({
  height: 1,
  width: 1,
})`
  border-style: 'none';
`;

const groqQuery = `
*[
    _type=="leadForm" && slug.current == 'apply'][0] {
    ...
}
`;

const StyledForm: typeof Box = styled(Box).attrs({
  width: ['100%', null, null, '60%'],
  position: 'relative',
})`
  form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .lead-form-field {
    width: 100%;
  }

  label {
    font-size: 1.0625rem;
    font-family: ${fonts.body};
    font-weight: ${fontWeights.regular};
    padding-bottom: 0.25rem;
  }

  label span {
    color: ${themeGet('colors.brightOrange')};
  }

  input[type='text'],
  input[type='email'],
  input[type='tel'],
  input[type='url'],
  input[type='checkbox'],
  select {
    border: 1px solid ${themeGet('colors.cream')};
    border-radius: 0.25rem;
  }

  input[type='text'],
  input[type='email'],
  input[type='tel'],
  input[type='url'],
  select {
    padding-left: 0.75rem;
  }

  select {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%23F8EAD5' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  }

  input[type='checkbox'] {
    background: none;
  }

  input[type='checkbox']:checked,
  input[type='checkbox']:active {
    background-color: ${themeGet('colors.cream')};
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23111E04' viewBox='0 0 16 16'%3E%3Cpath d='M12.207 4.793a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L6.5 9.086l4.293-4.293a1 1 0 0 1 1.414 0z'/%3E%3C/svg%3E");
  }

  .checkbox-fieldset {
    display: flex;
    flex-direction: column;
  }

  .checkbox-fieldset > div:nth-of-type(1) {
    display: flex;
    flex-direction: row;
  }

  .checkbox-fieldset > div:nth-of-type(1) input {
    order: 0;
    margin-top: 0.25rem;
  }

  .checkbox-fieldset > div:nth-of-type(1) label {
    order: 1;
    margin-left: 1rem;
  }

  .checkbox-fieldset > div:nth-of-type(2) > p {
    font-size: 0.875rem;
    color: ${themeGet('colors.sand')};
    margin-bottom: 0;
  }

  .checkbox-fieldset > div:nth-of-type(2) > p a {
    text-decoration: underline;
  }

  button[type='submit'] {
    width: 100%;
    min-width: 14.875rem;
    background-color: ${themeGet('colors.neonGreen')};
    color: ${themeGet('colors.darkGreen')};
    font-family: ${fonts.heading};
    font-weight: ${fontWeights.extraBold};
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    text-wrap: nowrap;
    text-align: center;
    border-width: 2px;
    border-radius: 6.25rem;
    padding: 0.5625rem 1.875rem 0.5rem;
    display: block;
    position: relative;
    transition: background-color 250ms;
  }

  button[type='submit']:hover {
    background-color: ${themeGet('colors.darkGreen')};
    color: ${themeGet('colors.neonGreen')};
  }

  button[type='submit'] div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-75%, -50%);
  }

  .error-text {
    color: ${themeGet('colors.brightOrange')};
    margin-top: 0.5rem;
  }

  .lead-form-field .error-text {
    margin-top: 0rem;
  }

  @media screen and (min-width: ${breakpoints[3]}) {
    .lead-form-field:nth-child(1),
    .lead-form-field:nth-child(2),
    .lead-form-field:nth-child(3),
    .lead-form-field:nth-child(4),
    .lead-form-field:nth-child(7),
    .lead-form-field:nth-child(8) {
      width: 50%;
      padding-right: 0.5rem;
    }

    .lead-form-field:nth-child(2),
    .lead-form-field:nth-child(4),
    .lead-form-field:nth-child(8) {
      padding-left: 0.5rem;
      padding-right: 0;
    }

    button[type='submit'] {
      width: auto;
      margin-right: 1rem;
    }
  }
`;

const StyledH2: typeof H2 = styled(H2).attrs({
  fontSize: ['2.5rem', null, null, '2.35rem', '3rem'],
})``;

const StyledHeadline: typeof HeadLine = styled(HeadLine).attrs({
  marginTop: '1rem',
})``;

const StyledP2: typeof P2 = styled(P2).attrs({
  marginTop: '0.5rem',
})``;

const StyledCTA: typeof CTA = styled(CTA).attrs({
  color: 'neonGreen',
  marginTop: '1rem',
  display: 'block',
})`
  letter-spacing: 0.1rem;
  text-transform: uppercase;

  &:hover {
    text-decoration: underline;
  }
`;

const handleClickLink = (clickText: string, clickUrl: string) => {
  Events.didClickLink({clickText, clickUrl});
};

const ApplyPage: React.FC = () => {
  const {data, error} = useSWR([groqQuery], query => sanityClient.fetch(query));

  const [formKey, setFormKey] = useState(Date.now().toString());

  useEffect(() => {
    trackGoogleAdsPageLoad();
  }, []);

  const eventData = {page: 'Apply', form: 'Application'};

  return (
    <Box backgroundColor="darkGreen" color="cream" paddingX={['1.5rem', null, null, '4rem']} paddingY="4rem">
      <Flex maxWidth="91.25rem" flexDirection={['column', null, null, 'row']} justifyContent="space-between">
        <StyledForm>
          <StyledH2>{copy.heading}</StyledH2>

          <Box mt="2rem">
            {data ? (
              <LeadForm {...data} customSuccessMessage={copy.successMessage} eventData={eventData} />
            ) : (
              <Loading />
            )}
          </Box>
        </StyledForm>

        <Box width={['100%', null, null, '30%']} mt={['4rem']}>
          <Box>
            <StyledHeadline>{copy.headlines.membership.headline}</StyledHeadline>

            <StyledP2>{copy.headlines.membership.description}</StyledP2>

            <Anchor
              onClick={() => handleClickLink('apply.headlines.membership', MEMBERSHIP_CRITERIA)}
              to={MEMBERSHIP_CRITERIA}
            >
              <StyledCTA>{copy.headlines.membership.cta}</StyledCTA>
            </Anchor>
          </Box>

          <Box gridColumn="1/end" width="100%" height="1px" mt="2rem" backgroundColor="neonGreen" />

          <Box mt="2rem">
            <StyledHeadline>{copy.headlines.inclusion.headline}</StyledHeadline>

            <StyledP2>{copy.headlines.inclusion.description}</StyledP2>

            <Anchor
              onClick={() => handleClickLink('apply.headlines.inclusion', COMMITMENT_TO_INCLUSION)}
              to={COMMITMENT_TO_INCLUSION}
            >
              <StyledCTA>{copy.headlines.inclusion.cta}</StyledCTA>
            </Anchor>
          </Box>
        </Box>
      </Flex>

      <SEOWithQuery title={seo.apply.title} description={seo.apply.description} />
    </Box>
  );
};

export default ApplyPage;
